import React from "react";
import { Link } from "react-router-dom"; // ✅ Added for navigation
import "../../styles/Subsections.css"; // ✅ Linked to universal stylesheet

// Import high-res and low-res WebP images from src/assets/subsectionpics
import retirementJourneyMapHighRes from "../../assets/subsectionpics/retirement-journey-map.webp";
import retirementJourneyMapLowRes from "../../assets/subsectionpics/retirement-journey-map-lowres.webp";
import retirementFoundationHighRes from "../../assets/subsectionpics/retirement-foundation.webp";
import retirementFoundationLowRes from "../../assets/subsectionpics/retirement-foundation-lowres.webp";
import { OptimizedImage } from "../../components/Media"; // Import OptimizedImage component

const Retirement = () => {
  const handleNavigation = (path) => {
    window.scrollTo(0, 0); // Scroll to top immediately
    setTimeout(() => {
      window.location.href = path; // Navigate after scroll
    }, 0);
  };

  return (
    <div className="subsection-container">
      <h1 className="subsection-title">Retirement</h1>

      <section>
        <h2>What Retirement Means to You</h2>
        <p>
          Retirement is a highly individualized goal, varying in definition, timing, and lifestyle. Some people envision complete financial independence, while others prefer to continue working in a reduced or passive capacity. The key to a successful retirement is planning early and tailoring your strategy to your unique vision.
        </p>
      </section>

      <section>
        <h2>What You Are Working Towards</h2>
        <p>
          Planning for retirement can feel overwhelming, leading many to delay taking action. However, as discussed in the Time Value of Money section, delaying only makes it harder. The best approach is to start now.
        </p>
        <p>Begin by defining what retirement looks like for you:</p>
        <ul>
          <li>When do you want to retire?</li>
          <li>Does retirement mean completely stopping work, or shifting to a more passive method of income generation?</li>
          <li>Where do you want to live?</li>
          <li>What lifestyle do you want to maintain?</li>
        </ul>
        <p>
          Once you clarify these details, you can work backward to determine how much money you will need and how to get there. For example, if you aim to retire at 65 and you are currently 20, estimate your target savings and calculate how much you need to contribute monthly—factoring in your initial capital and expected returns. Breaking the process into manageable steps makes it far less intimidating. Realize that no one is coming to save you for retirement—you cannot rely on the government, relatives, or other outside factors. It is up to you to build the retirement you want. The most important part? Starting.
        </p>
        <OptimizedImage
          lowResSrc={retirementJourneyMapLowRes}
          highResSrc={retirementJourneyMapHighRes}
          alt="Retirement Journey Map: Set Goals, Save Early, Invest Wisely"
          className="section-image"
          width="300"
          height="200"
        />
      </section>

      <section>
        <h2>Types of Retirement Accounts</h2>
        <p>
          After outlining your retirement goals, the next step is taking action by opening the right investment accounts. There are several options, each serving different purposes depending on your situation. Let us explore the most common options.
        </p>

        <h3>401(k) Accounts</h3>
        <p>
          A 401(k) is an employer-sponsored retirement account designed to encourage long-term savings. The main benefits include:
        </p>
        <ul>
          <li>
            <strong>Employer Matching Contributions:</strong> Many employers match a percentage of your contributions, effectively giving you free money. For example, if your salary is $100,000 and your employer offers a 100% match up to 5%, contributing $5,000 annually means your employer contributes an additional $5,000. If you invest less, you receive a lower match. If you invest more, your employer's match is capped at $5,000 since this is 5%.
          </li>
          <li>
            <strong>Tax Advantages:</strong> A traditional 401(k) allows you to contribute pre-tax income, lowering your taxable income now but taxing withdrawals in retirement. A Roth 401(k) works in reverse—you contribute after-tax dollars but withdraw tax-free in retirement.
          </li>
          <li>
            <strong>Automatic Contributions:</strong> Contributions are typically deducted from your paycheck, making investing seamless.
          </li>
        </ul>
        <p>
          1 key consideration is withdrawal restrictions—you generally cannot access funds penalty-free before age 59.5. Given the tax advantages and employer match, you should contribute at least enough to get the full match to avoid leaving “free money” on the table.
        </p>

        <h3>Roth IRA vs. Traditional IRA</h3>
        <p>
          Both Roth IRAs and Traditional IRAs are retirement accounts that differ primarily in taxation:
        </p>
        <ul>
          <li>
            <strong>Traditional IRA:</strong> Contributions are made with pre-tax income, reducing your taxable income now. However, withdrawals are taxed in retirement.
          </li>
          <li>
            <strong>Roth IRA:</strong> Contributions are made with after-tax dollars, meaning you will not owe taxes on withdrawals, including gains.
          </li>
        </ul>
        <p>
          The best option depends largely on your age:
        </p>
        <ul>
          <li>
            If you are young, a Roth IRA is often preferable because you have decades of compounding growth ahead, and avoiding taxes on withdrawals (gains) can save a significant amount in the long run.
          </li>
          <li>
            If you are older, a Traditional IRA may be better since it allows larger upfront contributions and immediate tax benefits.
          </li>
        </ul>

        <h3>Traditional Brokerage Account</h3>
        <p>
          A traditional brokerage account is a taxable investment account that provides flexibility but lacks tax advantages. It can be useful for:
        </p>
        <ul>
          <li>
            <strong>Early retirement:</strong> Since 1(k) and IRA funds are locked until age 59.5 (without penalties), a brokerage account allows access to funds at any age.
          </li>
          <li>
            <strong>Exceeding contribution limits:</strong> If you max out your tax-advantaged accounts but want to invest more, a brokerage account provides an additional investment vehicle.
          </li>
        </ul>
      </section>

      <section>
        <h2>Summary</h2>
        <p>
          Each of these accounts serves a different purpose, and you do not have to choose just 1. Many successful investors use a combination of 401(k), IRA, and brokerage accounts to optimize their retirement strategy. The key is understanding your long-term goals and selecting the accounts that best align with your plan. Most importantly, start now—time is your greatest asset when it comes to building wealth for retirement.
        </p>
        <OptimizedImage
          lowResSrc={retirementFoundationLowRes}
          highResSrc={retirementFoundationHighRes}
          alt="Retirement Foundation: Building Blocks for a Secure Future"
          className="section-image"
          width="300"
          height="200"
        />
      </section>

      {/* ✅ Added Button to Move to Practical Applications */}
      <div className="cta-container">
        <Link
          to="/practical-applications"
          className="cta-button"
          onClick={(e) => {
            e.preventDefault(); // Prevent React Router's default navigation
            handleNavigation("/practical-applications");
          }}
        >
          Go To Applications
        </Link>
      </div>
    </div>
  );
};

export default Retirement;